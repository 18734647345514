<style scoped>
.disabled {
  color: #b0b0b0;
}
</style>
<template>
  <v-container>
    <h1>Users</h1>
    <v-btn :to="{name: 'InviteUsers'}" depressed color="primary">Invite Users</v-btn>

    <v-row>
      <v-col cols="12" md="6">
        <v-text-field v-model="search" label="search" dense filled outlined class="mt-6" prepend-inner-icon="fa-search" />
      </v-col>
    </v-row>

    <v-data-table :items="users" :headers="headers" :search="search">
      <template v-slot:item="{ headers, item }">
        <tr :class="item.role === 'no_access' ? 'disabled' : ''">
          <td v-for="(header, key) in headers" class="table-row" :key="key">

            <span v-if="header.value === 'role'">
              {{ item.role.replace('_', ' ') }}
            </span>

            <span v-else-if="header.value === 'actions'">
              <v-menu>
                <template #activator="{ on }">
                  <v-btn icon v-on="on">
                    <v-icon>fas fa-ellipsis-h</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item :to="`/organization/settings/users/${item.id}`">
                    <v-list-item-title>View Details</v-list-item-title>
                  </v-list-item>

                  <template v-if="!userIsDisabled(item)">
                    <v-list-item v-if="item.id !== currentId" @click="requestPasswordReset(item.id)">
                      <v-list-item-title>
                        Send Password Reset Email
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item v-if="item.id !== currentId" @click="revokeAccess(item.id)">
                      <v-list-item-title class="red--text">
                        Revoke Organization Access
                      </v-list-item-title>
                    </v-list-item>
                  </template>

                  <template v-else>
                    <v-list-item @click="restoreAccess(item.id)">
                      <v-list-item-title>
                        Restore Organization Access
                      </v-list-item-title>
                    </v-list-item>
                  </template>

                </v-list>
              </v-menu>
            </span>

            <span v-else>
              {{ item[header.value] }}
            </span>
          </td>
        </tr>
      </template>
    </v-data-table>

    <h5 v-if="invites.length !== 0">Invited Users</h5>
    <v-data-table v-if="invites.length !== 0" :items="invites" :headers="inviteHeaders">
      <template #item.actions="{ item }">
        <v-btn :loading="resending === item.token" @click="resend(item.token)" class="mr-3" x-small color="primary"><v-icon x-small left>fa-sync-alt</v-icon>Resend Invite</v-btn>
        <v-btn :loading="deleting === item.token" @click="deleteInvite(item.token)" x-small color="error"><v-icon x-small left>fa-trash</v-icon>Revoke Now</v-btn>
      </template>
      <template #item.link="{ item }">
        <v-tooltip top :value="tooltip === item.token">
          <template #activator="{}">
            <v-text-field @click="copy($event, item.token)" dense readonly :value="inviteUrl(item.token)" />
          </template>
          Copied!
        </v-tooltip>
      </template>
      <template  #item.expires="{ item }">
        {{ formatDate(item.expires) }}
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import event, { Events } from '../../../event'
import DateMixin from '../../../mixins/DateMixin'

export default {
  name: 'Users',
  mixins: [DateMixin],
  data: () => ({
    actions: '',
    tooltip: 0,
    resending: '',
    deleting: '',
    search: null,
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Email', value: 'email' },
      { text: 'Role', value: 'role', filterable: false },
      { value: 'actions', filterable: false, sortable: false }
    ],
    inviteHeaders: [
      { text: 'Email', value: 'email' },
      { text: 'Invite Link', value: 'link' },
      { text: 'Expires', value: 'expires' },
      { value: 'actions', filterable: false, sortable: false }
    ]
  }),
  computed: {
    ...mapState({
      users: state => state.Organization.users,
      currentId: state => state.User.id,
      invites: state => state.Organization.invites
    }),
    ...mapGetters({
      url: 'Organization/apiUrl'
    })
  },
  created () {
    this.$store
      .dispatch('Organization/getUsers')
      .then(() => {
        this.users.sort((a, b) => {
          return b.role === 'no_access' ? -1 : 1
        })
      })
  },
  methods: {
    userIsDisabled (user) {
      return user.role === 'no_access'
    },
    inviteUrl (token) {
      return process.env.VUE_APP_WEB_URL + '/signup/invite?token=' + token
    },
    requestPasswordReset (id) {
      event.emit(Events.CONFIRM, 'Send user a password reset?', () => {
        this.$axios.post(`${this.url}/user/${id}/reset-request`)
          .then(() => {
            event.emit(Events.SUCCESS, 'User password reset sent!')
          })
      }, { ok: 'Yes', cancel: 'Cancel', title: 'Password Reset Request' })
    },
    revokeAccess (id) {
      event.emit(Events.CONFIRM, 'Are you sure you want to revoke this users access?', () => {
        event.emit(Events.LOADING, true)
        this.$axios.delete(`${this.url}/user/${id}`)
          .then(() => {
            this.$store.dispatch('Organization/getUsers')
              .catch(() => {
                event.emit(Events.LOADING, false)
              })
              .then(() => {
                event.emit(Events.SUCCESS, 'User access removed!')
                event.emit(Events.LOADING, false)
              })
          })
      }, { ok: 'Yes, delete this user.', cancel: 'Cancel', title: 'Revoke Access?' })
    },
    restoreAccess (id) {
      event.emit(Events.CONFIRM, 'Are you sure you want to restore this user to basic access?', () => {
        event.emit(Events.LOADING, true)
        this.$axios
          .put(`${this.url}/user/${id}`, {
            role: 'basic'
          })
          .then(() => {
            this.$store.dispatch('Organization/getUsers')
              .catch(() => {
                event.emit(Events.LOADING, false)
              })
              .then(() => {
                event.emit(Events.SUCCESS, 'User access restored!')
                event.emit(Events.LOADING, false)
              })
          })
      }, { ok: 'Yes, restore this user.', cancel: 'Cancel', title: 'Restore Access?' })
    },
    resend (token) {
      this.resending = token
      this.$axios.post(`${this.url}/user/invite/${token}`)
        .then(() => {
          event.emit(Events.SUCCESS, 'Invite Resent!')
        })
        .finally(() => {
          this.resending = ''
        })
    },
    deleteInvite (token) {
      this.deleting = token
      this.$axios.delete(`${this.url}/user/invite/${token}`)
        .then(() => {
          this.$store.dispatch('Organization/getUsers')
        })
        .finally(() => {
          this.deleting = ''
        })
    },
    copy ($event, token) {
      $event.target.select()
      document.execCommand('copy')
      this.tooltip = token
      setTimeout(() => {
        this.tooltip = 0
      }, 2000)
    }
  }
}
</script>
