var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h1',[_vm._v("Users")]),_c('v-btn',{attrs:{"to":{name: 'InviteUsers'},"depressed":"","color":"primary"}},[_vm._v("Invite Users")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"mt-6",attrs:{"label":"search","dense":"","filled":"","outlined":"","prepend-inner-icon":"fa-search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"items":_vm.users,"headers":_vm.headers,"search":_vm.search},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('tr',{class:item.role === 'no_access' ? 'disabled' : ''},_vm._l((headers),function(header,key){return _c('td',{key:key,staticClass:"table-row"},[(header.value === 'role')?_c('span',[_vm._v(" "+_vm._s(item.role.replace('_', ' '))+" ")]):(header.value === 'actions')?_c('span',[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("fas fa-ellipsis-h")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"to":("/organization/settings/users/" + (item.id))}},[_c('v-list-item-title',[_vm._v("View Details")])],1),(!_vm.userIsDisabled(item))?[(item.id !== _vm.currentId)?_c('v-list-item',{on:{"click":function($event){return _vm.requestPasswordReset(item.id)}}},[_c('v-list-item-title',[_vm._v(" Send Password Reset Email ")])],1):_vm._e(),(item.id !== _vm.currentId)?_c('v-list-item',{on:{"click":function($event){return _vm.revokeAccess(item.id)}}},[_c('v-list-item-title',{staticClass:"red--text"},[_vm._v(" Revoke Organization Access ")])],1):_vm._e()]:[_c('v-list-item',{on:{"click":function($event){return _vm.restoreAccess(item.id)}}},[_c('v-list-item-title',[_vm._v(" Restore Organization Access ")])],1)]],2)],1)],1):_c('span',[_vm._v(" "+_vm._s(item[header.value])+" ")])])}),0)]}}])}),(_vm.invites.length !== 0)?_c('h5',[_vm._v("Invited Users")]):_vm._e(),(_vm.invites.length !== 0)?_c('v-data-table',{attrs:{"items":_vm.invites,"headers":_vm.inviteHeaders},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-3",attrs:{"loading":_vm.resending === item.token,"x-small":"","color":"primary"},on:{"click":function($event){return _vm.resend(item.token)}}},[_c('v-icon',{attrs:{"x-small":"","left":""}},[_vm._v("fa-sync-alt")]),_vm._v("Resend Invite")],1),_c('v-btn',{attrs:{"loading":_vm.deleting === item.token,"x-small":"","color":"error"},on:{"click":function($event){return _vm.deleteInvite(item.token)}}},[_c('v-icon',{attrs:{"x-small":"","left":""}},[_vm._v("fa-trash")]),_vm._v("Revoke Now")],1)]}},{key:"item.link",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","value":_vm.tooltip === item.token},scopedSlots:_vm._u([{key:"activator",fn:function(ref){return [_c('v-text-field',{attrs:{"dense":"","readonly":"","value":_vm.inviteUrl(item.token)},on:{"click":function($event){return _vm.copy($event, item.token)}}})]}}],null,true)},[_vm._v(" Copied! ")])]}},{key:"item.expires",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.expires))+" ")]}}],null,false,3855586741)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }